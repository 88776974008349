/* add css module styles here (optional) */

._3Ewis {
    width: 300px;
    position: relative;
    background: #eee;
}

._3Ewis>input {
    width: inherit;
    padding: 10px;
    border-radius: 5px;
    border-width: 1px;
    border-color: transparent;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    padding-left: 45px;
    padding-right: 30px;
    box-sizing: border-box;
}
._3Ewis>img {
    position: absolute;
    top: 3px;
    left: 10px;
}


._3Ewis ._3lFwG {
    border: 1px solid;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 5px 10px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border-color: aliceblue;
    max-height: 500px;
    overflow: scroll;
}

._3Ewis ._3lFwG ._36cXF {
    padding: 5px;
    cursor: pointer;
}
._3Ewis ._3lFwG ._36cXF:hover {
    background-color: beige;
}

._3Ewis ._3qqrl {
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #3498db; /* Blue */
    border-radius: 50%;
    -webkit-animation: _1sp4O 2s linear infinite;
            animation: _1sp4O 2s linear infinite;

    position: absolute;
    top: 5px;
    right: 5px;
    width: 10px;
    height: 10px;
}
  
@-webkit-keyframes _1sp4O {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
  
@keyframes _1sp4O {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}